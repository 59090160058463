import React, { useEffect, useRef } from 'react'
import './ChannelList.css'
import TeacherChannelItem from '../ChannelItem/TeacherChannelItem'
import ParentChannelItem from '../ChannelItem/ParentChannelItem'
import Intercom, { showSpace } from '@intercom/messenger-js-sdk'
import CentreMessages from '../ChannelItem/IntercomItems/CentreMessages/CentreMessages'
import Announcements from '../ChannelItem/IntercomItems/Announcements/Announcements'
import { jwtDecode } from 'jwt-decode'
import { getPreferredCentre } from '../../utils/api'
import { useNavigate } from 'react-router-dom'
import { logError, logMessage } from '../../sentry'
import InfiniteScroll from 'react-infinite-scroll-component'
import Mixpanel from '../../utils/mixpanel'
import { ANALYTIC_EVENTS } from '../../utils/constants'

const ChannelList = ({
  channels,
  displayMeta,
  showIntercom,
  isLoading,
  fetchMoreChannels,
  hasMoreChannels
}) => {
  const navigate = useNavigate()

  const token = localStorage.getItem('token')
  const decodedToken = jwtDecode(token)
  const userId = decodedToken?.sub
  const [preferredCentre, setPreferredCentre] = React.useState('')
  const [openNews, setOpenNews] = React.useState(false)
  const iframeRef = useRef(null)
  const [loading, setLoading] = React.useState(true)

  const customiseIntercom = () => {
    const intervalId = setInterval(() => {
      const iframes = document.querySelectorAll('iframe')

      const messagePreview = document.querySelector(
        'iframe[name="intercom-notifications-frame"]'
      )
      if (messagePreview) {
        messagePreview.remove()
        clearInterval(intervalId)
      }

      iframes.forEach((iframe) => {
        if (iframe && iframe.contentDocument) {
          iframeRef.current = iframe
          const iframeDocument =
            iframe.contentDocument || iframe.contentWindow.document

          const bottomNavBar = iframeDocument.querySelectorAll(
            'div[data-testid="spaces-tab-bar"]'
          )
          bottomNavBar.forEach((element) => {
            element.style.display = 'none'
            clearInterval()
          })

          const newsHeading = iframeDocument.querySelector('h1')
          if (newsHeading && newsHeading.innerText === 'News') {
            newsHeading.innerText = 'Announcements'
          }

          const innerNewsHeading = iframeDocument.querySelector('h1')
          if (innerNewsHeading && innerNewsHeading.innerText === 'News') {
            innerNewsHeading.innerText = 'Announcements'
            clearInterval()
          }
        }
      })
    }, 15)

    return () => clearInterval(intervalId)
  }

  useEffect(() => {
    if (isLoading && !channels.length) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [isLoading])

  //initialise intercom
  useEffect(() => {
    const initializeIntercom = async () => {
      try {
        if (showIntercom && Intercom) {
          Intercom({
            app_id: 'xfyr5g2q',
            user_id: userId,
            hide_default_launcher: true
          })
        } else {
          console.error('Intercom is not loaded')
        }
      } catch (error) {
        logError(error)
        logMessage('Failed to initialize Intercom')
      }
    }
    initializeIntercom()
  }, [userId])

  useEffect(() => {
    customiseIntercom()
  }, [])

  useEffect(() => {
    const fetchPreferredCentre = async () => {
      try {
        if (showIntercom) {
          const data = await getPreferredCentre()
          setPreferredCentre(data?.preferred_center)
        }
      } catch (error) {
        logError(error, 'Error fetching preferred centre')
        throw error
      }
    }
    fetchPreferredCentre()
  }, [userId])

  useEffect(() => {
    if (openNews === true) {
      showSpace('news')
      customiseIntercom()
      setOpenNews(false)
    }
  }, [openNews])

  const showIntercomNews = () => {
    setOpenNews(true)
  }

  const showIntercomMessages = () => {
    navigate('show_intercom_messages', { replace: true })
  }

  const onChannelClickHandler = (channel) => {
    console.log(channel)
    Mixpanel.trackEvent({
      event: ANALYTIC_EVENTS.THREAD_CLICKED,
      data: {
        teacher_name: channel?.details?.teacher_search_meta?.teacher_name,
        program_name:
          channel?.details?.parent_display_meta?.chat_title?.split(':')?.[0],
        child_name: channel?.details?.parent_search_meta?.student_name,
        unread_count: channel?.unread_message_count
      }
    })
  }

  const spinningLoader = (
    <div className="inifinite-scroll-spinner-container">
      <div className="infinite-scroll-spinner"></div>
    </div>
  )

  return loading ? (
    <div className="spinner-container">
      <div className="spinner"></div>
    </div>
  ) : (
    <div className="channel-list">
      {showIntercom && preferredCentre ? (
        <div onClick={showIntercomMessages}>
          <CentreMessages centre={preferredCentre?.name} />
        </div>
      ) : null}
      {/* {showIntercom ?
        <div onClick={showIntercomNews}>
          <Announcements />
        </div> : null
      } */}
      {!channels?.length && displayMeta === 'teacher_display_meta' ? (
        <div className="no-chats-message">
          <span className="orange-highlight">Oh.</span> you don't have any chats
          at the moment.
        </div>
      ) : (
        <InfiniteScroll
          dataLength={channels.length}
          next={fetchMoreChannels}
          hasMore={hasMoreChannels}
          loader={spinningLoader}
          style={{ overflow: 'hidden' }}>
          {channels.map((channel) =>
            displayMeta === 'teacher_display_meta' ? (
              <TeacherChannelItem key={channel.uuid} channel={channel} />
            ) : (
              <ParentChannelItem
                key={channel.uuid}
                channel={channel}
                onChannelClickHandler={() => onChannelClickHandler(channel)}
              />
            )
          )}
        </InfiniteScroll>
      )}
    </div>
  )
}

export default ChannelList
