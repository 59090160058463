import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ChatHeader.css';
import backIcon from '../../assets/images/icon_back.svg';
import parentIcon from '../../assets/images/icon_parent.svg';

const ChatHeader = ({ title, titleLabel, chatIcon, chatLabel, channelUuid, teacherName }) => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    const userType = localStorage.getItem('userType');
    if (userType === 'teacher') {
      navigate('/teacher-home');
    } else if (userType === 'parent') {
      navigate('/parent-home');
    } else {
      navigate(-1); // Fallback in case userType is not set
    }
  };

  const handleIconClick = () => {
    navigate(`/profile/${channelUuid}`);
  };

  const renderChatIcon = () => {
    let initials = '';
    if (chatIcon) {
      return <img src={chatIcon} alt={title} className="chat-icon" onClick={handleIconClick} />;
    } else {
      if (String(title).indexOf(":") !== -1) {
        initials = (teacherName || '')
          .split(' ')
          .map((name) => name.charAt(0))
          .join('');
      }
      else {
        initials = (title || '')
          .split(' ')
          .map((name) => name.charAt(0))
          .join('');
      }
      return <div className="chat-icon-placeholder" onClick={handleIconClick}>{initials}</div>;
    }
  };

  return (
    <header className="chat-header">
      <button className="back-button" onClick={handleBackClick}>
        <img src={backIcon} alt="Back" />
      </button>
      <div className="chat-icon-container">
        {renderChatIcon()}
        {/* <span className="chat-header-label">{chatLabel}</span> */}
      </div>
      <div className="chat-title-container">
        <p className="chat-title">{title}</p>
        {titleLabel && titleLabel.trim() !== '' && (
          <span className="chat-title-label">
            <img src={parentIcon} className="label-icon-parent" /> {titleLabel}
          </span>
        )}
      </div>
    </header>
  );
};

export default ChatHeader;