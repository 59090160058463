import React, { useRef, useEffect } from 'react'
import Message from '../Message/Message'
import './MessageList.css'
import InfiniteScroll from 'react-infinite-scroll-component';

const MessageList = ({
  messages,
  fetchMessages,
  hasMoreData,
  channelUuid,
  teacherName,
  childName,
  programName,
  messageContainerRef
}) => {

  const spinningLoader = (
    <div className="inifinite-scroll-spinner-container">
      <div className="infinite-scroll-spinner"></div>
    </div>
  )

  return (
    <div className="messages-container" id="scrollableDiv" ref={messageContainerRef}
      style={{
        height: 300,
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column-reverse',
      }}>
      <InfiniteScroll
        dataLength={messages.length}
        next={fetchMessages}
        style={{ display: 'flex', flexDirection: 'column-reverse' }}
        inverse={true}
        hasMore={hasMoreData}
        loader={spinningLoader}
        scrollableTarget="scrollableDiv"
      >
        {messages.map((message) => (
          <Message
            key={message.uuid}
            message={message}
            channelUuid={channelUuid}
            teacherName={teacherName}
            childName={childName}
            programName={programName}
          />
        ))}
      </InfiniteScroll>
    </div >
  );
};

export default MessageList
