import { useEffect } from 'react';
import { useWebSocket } from '../context/WebSocketContext';
import { useParams } from 'react-router-dom';

const usePresenceUpdate = (interval = 1000) => {
    const ws = useWebSocket();
    const { uuid: channelId } = useParams();

    useEffect(() => {
        if (!ws) return;

        const sendPresenceUpdate = () => {
            if (ws.readyState === WebSocket.OPEN) {
                const presenceMessage = {
                    presence: true,
                    channel_id: channelId,
                };
                ws.send(JSON.stringify(presenceMessage));
            }
        };

        const presenceInterval = setInterval(sendPresenceUpdate, interval);

        return () => clearInterval(presenceInterval);
    }, [ws, channelId, interval]);
};

export default usePresenceUpdate;