import React from 'react'
import './Message.css'
import TextMessage from './TextMessage'
import ImageMessage from './Moments/ImageMessage'
import VideoMessage from './Moments/VideoMessage'
import FileMessage from './FileMessage'
import ReportMessage from './ReportMessage'
import Iframe from '../IFrame/IFrame'
import { useNavigate } from 'react-router-dom'
import readIcon from '../../assets/images/icon_read_reciept.svg'
import unReadIcon from '../../assets/images/icon_unread_reciept.svg'
import avatarPoh from '../../assets/images/avatar-poh.svg'
import { formatDateString } from '../../helpers'
import PohMessage from './PohMessage'


const Message = ({
  key,
  message,
  channelUuid,
  teacherName,
  programName,
  childName
}) => {
  const [modalUrl, setModalUrl] = React.useState('')
  const navigate = useNavigate()
  const userType = localStorage.getItem('userType')

  const analyticData = {
    teacher_name: teacherName,
    program_name: programName,
    child_name: childName
  }

  const openModal = (url) => {
    if (url.startsWith('http')) {
      setModalUrl(url)
    } else {
      // Handle deeplink
      navigate(url)
    }
  }

  const closeModal = () => {
    setModalUrl('')
  }

  const handleIconClick = (author_name) => {
    if (author_name === 'Master Poh') {
      navigate(`/profile/poh/`)
    } else {
      navigate(`/profile/${channelUuid}`)
    }
  }

  const renderMessageContent = (content, authorType, authorName = '') => {
    switch (content.type) {
      case 'text':
        return (
          <TextMessage
            content={content}
            openModal={openModal}
            authorType={authorType}
            authorName={authorName}
            analyticData={analyticData}
          />
        )
      case 'image':
        return (
          <ImageMessage
            content={content}
            openModal={openModal}
            authorType={authorType}
            analyticData={analyticData}
            message={message}
          />
        )
      case 'video':
        return (
          <VideoMessage
            content={content}
            openModal={openModal}
            authorType={authorType}
            analyticData={analyticData}
            message={message}
          />
        )
      case 'file':
        return (
          <FileMessage
            content={content}
            openModal={openModal}
            authorType={authorType}
            analyticData={analyticData}
          />
        )
      case 'report':
        return (
          <ReportMessage
            content={content}
            openModal={openModal}
            authorType={authorType}
            authorName={authorName}
            analyticData={analyticData}
          />
        )
      default:
        return null
    }
  }

  const renderMessageIcon = (author_name, author_icon) => {
    if (author_icon) {
      return (
        <img
          src={author_icon}
          alt={author_name}
          onClick={() => handleIconClick(author_name)}
          className="message-icon"
        />
      )
    } else {
      if (author_name === 'Master Poh') {
        return (
          <img
            src={avatarPoh}
            alt={author_name}
            onClick={() => handleIconClick(author_name)}
            className="message-icon"
          />
        )
      } else {
        const initials = author_name
          .split(' ')
          .map((name) => name.charAt(0))
          .join('')
        return (
          <div
            className="z-50 message-icon-placeholder"
            onClick={() => handleIconClick(author_name)}>
            {initials}
          </div>
        )
      }
    }
  }

  const authorName = message?.author_name?.split(' ')[0]?.toLowerCase();

  return (
    message?.content?.type === 'poh_v2' ?
      <PohMessage
        imageUrl={message?.author_icon}
        messageText={message?.content?.body}
        ctaText={message?.content?.cta[0]?.label}
        ctaLink={message?.content?.cta[0]?.value}
        timestamp={message?.created}
        analyticData={analyticData} />
      :
      <>
        <div
          key={message.uuid ? message.uuid : key}
          className={`message ${message.author_type === 'ME' ? 'me' : 'not-me'}`}>
          {/* {message.author_type === 'NOT ME' && (
          <div className="message-icon-container">
            {renderMessageIcon(message.author_name, message.author_icon)}
          </div>
        )} */}
          <div className={`message-content ${message.author_type === 'ME' ? 'me-content' : 'not-me-content'}`}>
            {renderMessageContent(message.content, message.author_type)}
          </div>
          <div className={`message-timestamp-container ${message.author_type === 'ME'
            ? 'me-timestamp-container'
            : 'not-me-timestamp-container'
            }`}>
            <span className="message-timestamp">
              {formatDateString(message.created)}
            </span>
            {message.author_type === 'ME' && (
              <div className="message-read-status">
                {message.status.toUpperCase() === 'READ' && (
                  <img src={readIcon} alt="read" className="read-status-icon" />
                )}
                {/* {message.status.toUpperCase() === 'SENT' && <img src={unReadIcon} alt="read" className="unread-status-icon" />} */}
              </div>
            )}
          </div>
        </div>
        {message.author_type === 'NOT ME' ?
          <div className='author-name'>{message.author_name.toLowerCase() === 'master poh' ? 'poh' : authorName} </div> : null}

        {modalUrl && <Iframe url={modalUrl} onClose={closeModal} />}
      </>
  )
}

export default Message