import React from 'react';
import './CentreMessages.css';
import intercomCentreIcon from '../../../../assets/images/icon_intercom_centre.svg'
import Intercom, { onUnreadCountChange } from '@intercom/messenger-js-sdk';
import { jwtDecode } from 'jwt-decode';
import { logError, logMessage } from '../../../../sentry';

const CentreMessages = (props) => {

  const token = localStorage.getItem('token')
  const decodedToken = jwtDecode(token)
  const userId = decodedToken?.sub
  const centreName = props?.centre
  const title = 'team ' + centreName
  const subtitle = 'send us a message'
  const [unreadIntercomMessageCount, setUnreadIntercomMessageCount] = React.useState(0);

  React.useEffect(() => {
    const initializeIntercom = async () => {
      try {
        if (Intercom) {
          Intercom({
            app_id: 'xfyr5g2q',
            user_id: userId,
            hide_default_launcher: true,
          });
          onUnreadCountChange(function (unreadCount) {
            setUnreadIntercomMessageCount(unreadCount)
          });
        } else {
          console.error('Intercom is not loaded');
        }
      } catch (error) {
        logError(error)
        logMessage('Failed to initialize Intercom')
      }
    };
    initializeIntercom();
  }, [userId]);

  const renderChatIcon = () => {
    return <img src={intercomCentreIcon} alt="Centre" className="chat-icon" />
  };

  return (
    <div
      className="intercom-item">

      <div className="chat-icon-container">
        {renderChatIcon()}
      </div>

      <div className="intercom-container">
        <div className='intercom-headings'>
          <h2 className="intercom-title">{title?.toLowerCase()}</h2>
          <div className="intercom-subtitle">{subtitle}</div>
        </div>
        {unreadIntercomMessageCount > 0 && (<div className="intercom-unread-badge">{unreadIntercomMessageCount}</div>)}
      </div>

    </div>
  );
};

export default CentreMessages;