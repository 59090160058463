import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getProfile } from '../../utils/api';
import Spinner from '../../components/Spinner/Spinner';
import './Profile.css';
import backIcon from '../../assets/images/icon_back.svg';
import { logError, logMessage } from '../../sentry';

const Profile = () => {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const data = await getProfile(uuid);
        setProfile(data);
      } catch (error) {
        logError(error)
        logMessage('Error fetching profile')
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [uuid]);

  const handleBackClick = () => {
    //console.log("Helllo")
    navigate(-1);
  };

  const renderProfileIcon = () => {
    if (profile.profile_icon) {
      return <img src={profile.profile_icon} alt={profile.profile_name} className="profile-icon" />;
    } else {
      const initials = profile.profile_name
        .split(' ')
        .map((name) => name.charAt(0))
        .join('');
      return <div className="profile-icon-placeholder">{initials}</div>;
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="profile-container">
      <header className="profile-header">
        <button className="back-button" onClick={handleBackClick}>
          <img src={backIcon} alt="Back" className="back-icon" />
        </button>
      </header>
      <div className="profile-content">
        <div className="profile-image-container">
          {renderProfileIcon()}
          {profile.profile_icon_labels && (
            <span className="profile-icon-label">
              {profile.profile_icon_labels.join(' | ')}
            </span>
          )}
        </div>
        <p className="profile-name">{profile.profile_name}</p>
        {profile.profile_labels && Array.isArray(profile.profile_labels) && profile.profile_labels.length > 1 && (
          <p className="profile-details">{profile.profile_labels.join(' • ')}</p>
        )}
        <div className="profile-description">
          <div className="description-header">{profile.description_header}</div>
          <div className="description-body">{profile.description_body}</div>
        </div>
      </div>
      <div className="profile-footer">
        <button className="go-to-chat-button" onClick={() => navigate(`/chat/${uuid}`)}>go to chat</button>
        <div className="chat-visibility">this chat is also visible to the Oh team</div>
      </div>
    </div>
  );
};

export default Profile;