import React, { useState } from 'react';
import './MessageInput.css';
import sendIcon from '../../assets/images/icon_send_chat.svg';
const MessageInput = ({ sendNewMessage }) => {
    const [message, setMessage] = useState('');
    const [rows, setRows] = useState(1);

    const handleInputChange = (e) => {
        const textareaLineHeight = 20; // Adjust based on your font size and padding
        const previousRows = e.target.rows;
        e.target.rows = 1; // Reset the number of rows to 1

        const currentRows = Math.floor(e.target.scrollHeight / textareaLineHeight);

        if (currentRows === previousRows) {
            e.target.rows = currentRows;
        }

        if (currentRows >= 5) {
            e.target.rows = 5;
            e.target.scrollTop = e.target.scrollHeight;
        }

        setRows(currentRows < 5 ? currentRows : 5);
        setMessage(e.target.value);
    };

    const handleSendClick = () => {
        if (message.trim()) {
            const newMessage = {
                type: 'text',
                body: message,
                cta: [],
                media: null
            };
            sendNewMessage(newMessage);
            setMessage('');
            setRows(1);
        }
    };

    return (
        <div className="input-container">
            <textarea
                className="message-input"
                placeholder="Type your message..."
                value={message}
                onChange={handleInputChange}
                rows={rows}
            />
            <button className="send-button" onClick={handleSendClick}>
                <img src={sendIcon} alt="Send" />
            </button>
        </div>
    );
};

export default MessageInput;