import React, { useState, useEffect } from 'react'
import {
  getChannels,
  getUnreadMessages,
} from '../../utils/api'
import ChannelList from '../../components/ChannelList/ChannelList'
import { useWebSocket } from '../../context/WebSocketContext'
import useDebounce from '../../hooks/useDebounce'
import searchIcon from '../../assets/images/icon_search.svg'
import closeIcon from '../../assets/images/icon_close.svg'
import searchPlaceholder from '../../assets/images/placeholder-search.svg'
import nosearchResult from '../../assets/images/placeholder-no-search-result.svg'
import { jwtDecode } from 'jwt-decode'
import './Home.css'
import { logError } from '../../sentry'
import Mixpanel from '../../utils/mixpanel'
import { ANALYTIC_EVENTS } from '../../utils/constants'

const ParentHome = () => {
  const [channels, setChannels] = useState([])
  const [error, setError] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [showSearch, setShowSearch] = useState(false)
  const [searchResults, setSearchResults] = useState([])
  const [canShowNoSearch, setCanShowNoSearch] = useState(false)
  const [userId, setUserId] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 1000)
  const ws = useWebSocket()

  const fetchChannels = async (searchTerm = '', showLoading = false) => {
    
    if (showLoading) {
      setIsLoading(true)
    }
    try {
      const data = await getChannels(searchTerm)
      if (searchTerm) {
        setSearchResults(data.results)
      } else {
        setChannels(data.results)
      }
      setError(null)
    } catch (error) {
      logError(error)
      setError('Error fetching chat header')
    } finally {
      if (showLoading) {
        setIsLoading(false)
        sessionStorage.setItem('loaderShown', 'true')
      }
      setCanShowNoSearch(true)
    }
  }

  useEffect(() => {
    const token = localStorage.getItem('token')
    const decodedToken = jwtDecode(token)
    const userId = decodedToken?.sub
    setUserId(userId)
  }, [])

  useEffect(() => {
    ;(async () => {
      const unreadMessages = await getUnreadMessages()
      Mixpanel.trackEvent({
        event: ANALYTIC_EVENTS.VIEWED_CHAT_PAGE,
        data: { unread_count: unreadMessages?.unread_message_count }
      })
    })()
  }, [])

  useEffect(() => {
    const loaderShown = sessionStorage.getItem('loaderShown') === 'true'
    fetchChannels('', !loaderShown)
  
    if (ws) {
      const handleMessage = (event) => {
        // Parse the message to check if we really need to refresh
        try {
          const data = JSON.parse(event.data)

          // Only fetch channels if the message indicates we need to
          if (data.author_type === 'NOT ME') {
            fetchChannels(debouncedSearchTerm, false)
          }
        } catch (error) {
          console.error('Error handling WebSocket message:', error)
        }
      }
  
      ws.onmessage = handleMessage
  
      return () => {
        ws.onmessage = null
      }
    }
  }, [ws, debouncedSearchTerm])

  useEffect(() => {
    if (debouncedSearchTerm) {
      fetchChannels(debouncedSearchTerm, false).then(() => {
        setCanShowNoSearch(true)
      })
    }
  }, [debouncedSearchTerm])

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value)
    setCanShowNoSearch(false)
  }

  const handleRefresh = async () => {
    await fetchChannels(debouncedSearchTerm)
  }

  const toggleSearchBar = () => {
    setShowSearch(!showSearch)
    setSearchTerm('')
    setSearchResults([])
    setCanShowNoSearch(false)
  }

  useEffect(() => {}, [])

  return (
    <div className="home-container">
      <header className="home-header">
        {!showSearch ? (
          <>
            <div className="chat-header-home">chat</div>
            <button className="search-icon" onClick={toggleSearchBar}>
              <img
                src={searchIcon}
                alt="Search"
                className="search-icon-image"
              />
            </button>
          </>
        ) : (
          <div className="search-bar">
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder="search here"
              className="search-input"
            />
            <button className="close-icon" onClick={toggleSearchBar}>
              <img src={closeIcon} alt="Close" className="close-icon-image" />
            </button>
          </div>
        )}
      </header>
      <div className="content">
        {isLoading ? (
          <div className="spinner-container">
            <div className="spinner"></div>
          </div>
        ) : showSearch ? (
          searchTerm === '' ? (
            <div className="search-results-container">
              <img src={searchPlaceholder} alt="no-search-result" />
              <p>
                search by name, level, milestone to
                <br />
                see results
              </p>
            </div>
          ) : searchResults.length > 0 ? (
            <ChannelList
              channels={searchResults}
              displayMeta="parent_display_meta"
              showIntercom={true}
            />
          ) : (
            canShowNoSearch && (
              <div className="search-results-container">
                <img src={nosearchResult} alt="no-search-result" />
                <p>
                  <b>oh. no results found</b>
                </p>
                <p>try searching by name, batch, level</p>
              </div>
            )
          )
        ) : (
          <ChannelList
            channels={channels}
            displayMeta="parent_display_meta"
            showIntercom={true}
          />
        )}
      </div>
      {/* {error && <div className="error-message">{error}</div>} */}
    </div>
  )
}

export default ParentHome
