import React from 'react'
import './TextMessage.css'
import Mixpanel from '../../utils/mixpanel'
import { ANALYTIC_EVENTS } from '../../utils/constants'

const TextMessage = ({
  content,
  openModal,
  authorType,
  authorName,
  analyticData
}) => {
  const userType = localStorage.getItem('userType')

  const ctaClickHandler = (e) => {
    Mixpanel.trackEvent({
      event: ANALYTIC_EVENTS.MESSAGE_CTA_CLICKED,
      data: {
        body: content.body,
        redirection: content.cta?.[0]?.value,
        ...analyticData
      }
    })
    e.preventDefault()
    openModal(content.cta[0].value)
  }

  return (
    <>
      <p>{content.body.trim()}</p>
      {content.cta && content.cta.length > 0 && (
        <div className="cta-container">
          <a
            href="#"
            className={`cta-button ${authorType === 'ME' ? 'me' : 'not-me'}`}
            onClick={ctaClickHandler}>
            {content.cta[0].label} →
          </a>
        </div>
      )}
    </>
  )
}

export default TextMessage